import { Injectable } from '@angular/core';

export interface IPathArray {
  label: string,
  routerLink: any,
  arrow?: boolean,
  queryParams?: any
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  public breadcrumbs: IPathArray[] | any = [];
  constructor() { }

  public setBreadcrumbs(path: IPathArray[]){
    this.breadcrumbs = path;
  }
}
